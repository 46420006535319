.footer {
    padding: 48px 0 80px;
    background-color: #000927;
    color: #ffffff;
    text-align: center;

    @media (min-width: 768px) {
        padding: 48px 0;
    }

    &-copyright {
        margin-top: 24px;
        color: #6c757d;
        font-size: 12px;
    }
}