
.banner {
    background-color: #EDFFFC;

    &-wrapper {
        padding: auto; 
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;
    }

    &-title {
        font-size: 36px;
        margin-bottom: 24px;
        line-height: 1.4;
        font-weight: bold;

        @media (min-width: 768px) {
            font-size: 32px;
        }
    
        @media (min-width: 1200px) {
            font-size: 40px;
        }
    }

    &-info {
        padding: 48px 0 0;

        @media (min-width: 768px) {
            flex: 0 0 auto;
            width: 58.33333333%;
        }

        @media (min-width: 992px) {
            padding-bottom: 48px;
            width: 50%;
        }

        &__bottom {
            display: flex;
            align-items: center !important;
            justify-content: center;
            padding: 16px 12px;
            margin: 4px 0;
            color: #000927;

            &-text {
                font-size: 16px;
                margin-right: 8px;
            }

            @media (min-width: 768px) {
                justify-content: flex-start;
            }
        }
    }

    &-list {
        padding: 8 0 0 0;
        margin-bottom: 24px;
        list-style: none;
        
        &_item {
            font-size: 16px;
            padding-left: 24px;
            position: relative;
            color: #000927;
            margin-bottom: 16px;

            &::before {
                display: block;
                content: '';
                width: 16px;
                height: 16px;
                position: absolute;
                top: 4px;
                left: 0;
                background-repeat: no-repeat;
                background-position: 0 0;
                background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="%2300EEC3"/><path d="M11 6L6.875 10.5L5 8.45455" stroke="%23000927" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            }
        }
    }

    &-btn {
        color: #fff;
        background-color: #FF3D00;
        font-weight: 700;
        padding: 16px 48px;
        margin: 4px 0;
        border-radius: 50px;
        display: block;
        text-align: center;
        font-size: 16px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        @media (min-width: 768px) {
            display: inline-block;
            font-size: 18px;
        }

        @media (any-hover: hover) {
            &:hover {
                color: #fff;
                background-color: #C33F00;
            }
        }
    }

    &-img {
        padding-right: 0;
        padding-left: 0;
        text-align: center;

        @media (min-width: 768px) {
            flex: 0 0 auto;
            width: 41.66666667%;
        }

        @media (min-width: 992px) {
            flex: 0 0 auto;
            width: 50%;
        }

        img {
            max-width: 100%;
        }
    }
}

.features {
    padding: 48px 0;
    background-color: #ffffff;

    &-container {
        
        @media (min-width: 768px) {
            padding: 48px 20px;
        }
    }

    &-title {
        font-size: 31px;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 24px;
        text-align: center;

        @media (min-width: 768px) {
            font-size: 40px;
        }

        @media (min-width: 992px) {
            font-size: 36px;
        }
    }

    &-items {
        text-align: center;
        display: grid;
        padding-top: 24px;
        grid-gap: 24px;

        @media (min-width: 768px) {
            text-align: left;
            grid-template-columns: repeat(4, 1fr)
        }
    }

    &-item {
        flex: 0 0 auto;
        width: 100%;
        border: 1px solid #dee2e6;
        padding: 16px;
        border-radius: 8px;
        height: 100%;

        @media (min-width: 992px) {
            padding: 24px;
        }

        &__img {
            margin-top: 8px;
            margin-bottom: 24px;

            @media (min-width: 992px) {
                margin-top: 0;
            }
        }

        &__title {
            font-size: 18px;
            margin-bottom: 8px;
            margin-top: 0;
            font-weight: bold;
        }

        &__text {
            margin-bottom: 16px;
            font-size: 14px;
        }
    }

    &-actions {
        margin-top: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-btn {
        color: #fff;
        background-color: #FF3D00;
        font-weight: 700;
        padding: 16px 48px;
        margin: 4px 0;
        border-radius: 50px;
        display: block;
        text-align: center;
        font-size: 16px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        @media (min-width: 768px) {
            display: inline-block;
            font-size: 18px;
        }

        @media (any-hover: hover) {
            &:hover {
                color: #fff;
                background-color: #C33F00;
            }
        }
    }
}

.steps {
    background-color: #00eec3;
    padding: 48px 0;

    &-title {
        text-align: center;
        font-weight: bold;
        margin-bottom: 24px;
        font-size: 31px;
        line-height: 1.2;
        margin-top: 0;

        @media (min-width: 768px) {
            font-size: 40px;
        }

        @media (min-width: 768px) {
            font-size: 36px;
        }
    }

    &-items {
        display: grid;

        @media (min-width: 768px) {
            text-align: left;
            grid-template-columns: repeat(3, 1fr)
        }
    }

    &-item {
        text-align: center;
        &__img {
            padding: 16px 0;

            img {
                max-width: 100%;
            }

            @media (min-width: 768px) {
                position: relative;
            }


            .step-arrow {
                display: none;

                @media (min-width: 768px) {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    width: 80%;
                    transform: translate(-52%,-75%);
                }

                @media (min-width: 992px) {
                    width: auto;
                }
            }
        }

        &__description {

            @media (min-width: 768px) {
                padding:  0 16px;
            }
        }

        &__number {
            width: 28px;
            height: 28px;
            line-height: 28px;
            background-color: #ffffff;
            text-align: center;
            font-weight: bold;
            display: inline-block;
            border-radius: 50%;
        }

        &__title {
            font-size: 20px;
            font-weight: bold;
            margin-top: 16px;
            margin-bottom: 8px;
        }

        &__text {
            font-size: 14px;
            margin-bottom: 0;
            margin-top: 0;
        }
    }
}

.advantages {
    background-color: #ffffff;
    padding: 48px 0;

    &-title {
        font-size: 31px;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 24px;
        text-align: center;

        @media (min-width: 768px) {
            font-size: 40px;
        }

        @media (min-width: 992px) {
            font-size: 36px;
        }
    }

    &-items {
        text-align: center;
        display: grid;
        padding-top: 24px;
        grid-gap: 24px;

        @media (min-width: 768px) {
            text-align: left;
            grid-template-columns: repeat(4, 1fr)
        }
    }

    &-item {
        border-radius: 8px;
        text-align: center;
        padding: 16px;
        height: 100%;
        border: 1px solid #dee2e6;

        &__img {
            margin: 16px 0;

            img {
                max-width: 100%;
            }
        }

        &__title {
            font-size: 18px;
            margin-bottom: 8px;
            font-weight: bold;
        }

        &__text {
            font-size: 14px;
            margin-bottom: 16px;
        }
    }

    &-actions {
        margin-top: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-btn {
        color: #fff;
        background-color: #FF3D00;
        font-weight: 700;
        padding: 16px 48px;
        margin: 4px 0;
        border-radius: 50px;
        display: block;
        text-align: center;
        font-size: 16px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        @media (min-width: 768px) {
            display: inline-block;
            font-size: 18px;
        }

        @media (any-hover: hover) {
            &:hover {
                color: #fff;
                background-color: #C33F00;
            }
        }
    }
}

.about-section {
    background-color: #EDFFFC;

    &__title {
        font-size: 31px;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 24px;
        text-align: center;
    
        @media (min-width: 768px) {
            font-size: 40px;
            text-align: left;
        }
    
        @media (min-width: 992px) {
            font-size: 36px;
        }
    }

    &__wrapper {
        display: grid;

        @media (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
            align-items: flex-end;
        }
    }

    &__description {
        text-align: center;
        padding: 48px 0;
        order: 1;

        @media (min-width: 768px) {
            text-align: left;
            order: 2;
        }

        @media (min-width: 992px) {
            padding: 65px;
        }
    }

    &__img {
        order: 2;
        text-align: center;

        img {
            max-width: 100%;
        }

        @media (min-width: 768px) {
            order: 1;
        }
    }

    &--light {
        background-color: #ffffff;a

        .about-section__descriptio {
            order: 1;
    
            @media (min-width: 768px) {
                order: 1;
            }
        }

        .about-section__img {
            order: 2;
    
            img {
                max-width: 100%;
            }
    
            @media (min-width: 768px) {
                order: 2;
            }
        }
    }

    &__actions {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 768px) {
            justify-content: flex-start;
        }
    }

    &__btn {
        color: #fff;
        background-color: #FF3D00;
        font-weight: 700;
        padding: 16px 48px;
        margin: 4px 0;
        border-radius: 50px;
        display: block;
        text-align: center;
        font-size: 16px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        @media (min-width: 768px) {
            display: inline-block;
            font-size: 18px;
        }

        @media (any-hover: hover) {
            &:hover {
                color: #fff;
                background-color: #C33F00;
            }
        }
    }
}

.reviews {
    background-color: #EDFFFC;
    padding: 48px 0;

    &-title {
        font-size: 31px;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 24px;
        text-align: center;

        @media (min-width: 768px) {
            font-size: 40px;
        }

        @media (min-width: 992px) {
            font-size: 36px;
        }
    }

    &-container {
        @media (min-width: 992px) {
            padding: 48px 0;
        }
    }

    &-wrapper {
        display: grid;
        grid-gap: 24px;

        @media (min-width: 768px) {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 24px;
        }
    }

    &-item {
        background-color: #ffffff;
        border-radius: 8px;
        padding: 16px;
        height: 100%;
        border: 1px solid #dee2e6;

        &__header {
            display: flex;
            align-items: center;
        }

        &__photo {
            width: 56px;
            height: 56px;
            margin-right: 16px;

            img {
                max-width: 100%;
            }
        }

        &__name {
            font-weight: bold;
        }

        &__location {
            color: #6c757d;
        }

        &__text {
            margin-top: 24px;
            margin-bottom: 8px;
            font-size: 14px;
        }

        &__rate {
            margin-top: 16px;
            display: flex;
            align-items: center;

            .icon-star-f {
                margin-right: 8px; 
                
                &:after {
                    display: block;
                    content: '';
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 1.5rem;
                    height: 1.5rem;
                    background-image: url('data:image/svg+xml,<svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.5841 1.09048C10.9338 0.292234 12.0662 0.292233 12.4159 1.09048L14.6816 6.26151C14.8267 6.59262 15.1392 6.81966 15.4989 6.85532L21.117 7.41217C21.9843 7.49813 22.3342 8.57509 21.6831 9.15439L17.4653 12.9071C17.1952 13.1474 17.0758 13.5148 17.1531 13.8679L18.3596 19.3831C18.5458 20.2345 17.6297 20.9001 16.8776 20.4599L12.0051 17.6081C11.6931 17.4255 11.3069 17.4255 10.9949 17.6081L6.12245 20.4599C5.3703 20.9001 4.45418 20.2345 4.64043 19.3831L5.84691 13.8679C5.92416 13.5148 5.8048 13.1474 5.53473 12.9071L1.31691 9.15439C0.665819 8.57509 1.01574 7.49813 1.883 7.41217L7.50107 6.85532C7.86081 6.81966 8.1733 6.59263 8.31838 6.26151L10.5841 1.09048Z" fill="%23FFD770"/></svg>');                
                }
            }
        }
    }

    
    &-actions {
        margin-top: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-btn {
        color: #fff;
        background-color: #FF3D00;
        font-weight: 700;
        padding: 16px 48px;
        margin: 4px 0;
        border-radius: 50px;
        display: block;
        text-align: center;
        font-size: 16px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        @media (min-width: 768px) {
            display: inline-block;
            font-size: 18px;
        }

        @media (any-hover: hover) {
            &:hover {
                color: #fff;
                background-color: #C33F00;
            }
        }
    }
}

.why {
    padding: 48px 0;
    background-color: #ffffff;

    &-container {
        @media (min-width: 992px) {
            padding: 48px 0;
        }
    }

    &-title {
        font-size: 31px;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 24px;
        text-align: center;

        @media (min-width: 768px) {
            font-size: 40px;
        }

        @media (min-width: 992px) {
            font-size: 36px;
        }
    } 

    &-items {
        text-align: center;
        display: grid;
        padding-top: 24px;
        grid-gap: 24px;

        @media (min-width: 768px) {
            text-align: left;
            grid-template-columns: repeat(3, 1fr)
        }
    }

    &-item {
        padding: 24px;
        text-align: center;
        
        @media (min-width: 768px) {
          
        }

        &__icon {
            margin-bottom: 16px;
            
            img {
                max-width: 100%;
            }
        }

        &__title {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 8px;
            margin-top: 0;
        }

        &__text {

        }
    }
}

.sign-up {
    padding: 48px 0;
    background-color: #EDFFFC;

    &__title {
        margin-top: 0;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 28px;
        line-height: 1.4; 
        margin-bottom: 24px;
        text-align: center;
    }

    &__actions {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__btn {
        color: #fff;
        background-color: #FF3D00;
        font-weight: 700;
        padding: 16px 48px;
        margin: 4px 0;
        border-radius: 50px;
        display: block;
        text-align: center;
        font-size: 16px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        width: 100%;


        @media (min-width: 768px) {
            display: inline-block;
            font-size: 18px;
            width: auto;
        }

        @media (any-hover: hover) {
            &:hover {
                color: #fff;
                background-color: #C33F00;
            }
        }
    }
}

