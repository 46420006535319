*,
*::before,
*::after {
  margin: 0;
  padding: 0;
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

img {
	vertical-align: middle;
}

html,
body {
  height: 100%;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #000927;
}

.container {
  width: 100%;
	padding-right: 20px;
	padding-left: 20px;
	margin-right: auto;
	margin-left: auto;

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1400px) {
    max-width: 1320px;
  }  
}

.wrapper {
  padding-top: 64px;
}


.totop {
  background-color: #00eec3;
  display: none;
  position: fixed;
  z-index: 1000;
  right: 1%;
  bottom: 80px;
  width: 40px;
  height: 40px;
  border: solid 1px #00eec3;

  &.active {
      display: block;
  }

  &::after {
      display: block;
      content: '';
      width: 50%;
      height: 50%;
      margin: 25%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('data:image/svg+xml,<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 12H6.5V21H15.5V12H21.5L11 0L0.5 12Z" fill="%23fff"/></svg>');
  }
}


.scrollshow {
  background-color: #EDFFFC;
  transition: all 0.3s;
  align-items: center;
  width: 100%;
  left: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  box-shadow:0 0 1rem rgb(0, 0, 0, .6);
  z-index: 5;
  padding: 4px 24px;
  opacity: 0;

  &.active {
    opacity: 1;
  }

  @media (min-width: 768px) {
    display: none;
  }

  &-btn {
    color: #fff;
    background-color: #FF3D00;
    font-weight: 600;
    border-radius: 50px;
    padding: 8px 24px;
    margin: 4px 0;
    width: 100%;
    display: block;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}